<template>
  <div
    v-if="!userStore.isGlobalMarket"
    class="grid grid-cols-1 md:grid-cols-4 gap-6 h-full"
  >
    <home-blocks-wrapper
      id="melydemy"
      :title="LL.learn_programming_with()"
      highlight-title="Mely Demy"
      :link-view-more="MELY_DEMY"
      class="md:col-span-3"
    >
      <p class="text-gray-400 dark:text-gray-600">
        {{ LL.js_udemy_course_description() }}
      </p>
      <div class="flex items-center justify-between mt-10">
        <div class="grid grid-cols-2 gap-6 sm:gap-[50px]">
          <div
            v-for="i in courseInfo"
            :key="i.type"
            class="flex items-center gap-3 sm:gap-6"
          >
            <div :class="i.blockIconClass">
              <SvgUse
                :name="i.iconName"
                path="/svgs/home.svg"
                class="w-6 h-6 sm:w-11 sm:h-11 text-inherit fill-none"
              />
            </div>
            <div class="space-y-4">
              <p class="text-xl sm:text-3xl font-semibold">
                {{ i.measurement }}
              </p>
              <p class="text-sm sm:text-xl text-gray-500">{{ i.type }}</p>
            </div>
          </div>
        </div>
        <ImagePreset
          src="/images/home/blocks/melydemy.png"
          width="328"
          height="315"
          class="hidden sm:block"
          alt="Mely Demy - Khoá học rẻ nhất"
        />
      </div>
    </home-blocks-wrapper>
    <LazyBanner location="home-page" />
  </div>
  <div v-if="userStore.isFptMarket" class="grid grid-cols-1 md:grid-cols-4 gap-6 h-full">
    <home-blocks-wrapper
      id="melydemy"
      title="Tham gia cùng"
      highlight-title="Techmely"
      class="md:col-span-3"
    >
      <div class="flex items-center justify-between mt-10">
        <div class="flex flex-col mr-4">
          <p class="text-2xl mb-2">
            Chúng tôi đang tìm thành viên lập trình frontend và backend để cùng nhau phát
            triển sản phẩm
          </p>
          <div class="grid grid-cols-2 gap-6 sm:gap-[50px]">
            <UButton
              variant="soft"
              color="pink"
              size="xl"
              icon="i-heroicons-arrow-right"
              to="https://docs.google.com/forms/d/e/1FAIpQLSeLFv5gTJpribLQHxn9xtCRF1H6qKt1_0NZ94RbMQAOgvxJHw/viewform?usp=sf_link"
              target="_blank"
              >Frontend</UButton
            >
            <UButton
              variant="soft"
              color="pink"
              size="xl"
              icon="i-heroicons-arrow-right"
              to="https://docs.google.com/forms/d/e/1FAIpQLSeCvgdTda2-ei_rdHxBG7Gzg03HEqfPCKYQmml716IYiadRhg/viewform?usp=sf_link"
              target="_blank"
              >Backend</UButton
            >
          </div>
        </div>
        <img
          src="https://i.imgur.com/wQGzPou.png"
          width="218"
          height="276"
          class="hidden sm:block"
        >
      </div>
    </home-blocks-wrapper>
    <div class="md:col-span-1 flex gap-4 flex-row md:flex-col">
      <LazyBanner location="home-page" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { MELY_DEMY } from "~/constants";
import { typesafeI18n } from "~/locales/i18n-vue";
import { useUserStore } from "~/store";

const userStore = useUserStore();

const { LL } = typesafeI18n();

const courseInfo = [
  {
    type: LL.value.course(),
    measurement: "20+",
    iconName: "graduate-hat",
    blockIconClass: "p-4 rounded-lg bg-teal-50 dark:bg-teal-950 text-teal-500",
  },
  {
    type: LL.value.students(),
    measurement: "500+",
    iconName: "graduate-hat",
    blockIconClass: "p-4 rounded-lg bg-pink-50 dark:bg-pink-950 text-pink-500",
  },
  {
    type: LL.value.price_discount(),
    measurement: "29K",
    iconName: "camera",
    blockIconClass: "p-4 rounded-lg bg-amber-50 dark:bg-amber-950 text-amber-500",
  },
  {
    type: LL.value.ratings(),
    measurement: "5*",
    iconName: "multiple-persons",
    blockIconClass: "p-4 rounded-lg bg-cyan-50 dark:bg-cyan-950 text-cyan-500",
  },
];
</script>
